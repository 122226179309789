.oneSeller{
    width: 80% !important;
    background-color: transparent;
    padding: 10px ;
    margin: 20px !important;
    transition: all .3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}
.oneSeller h3{
    text-align: center;
    color: #5f5f5f;
    font-size: 22px;
}
.oneSeller:hover .oneSeller img{
    width: 100%;
    border-radius: 10px;
}
.center_img{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.seller_img{
    width: 160px;
    height: 160px;
    padding: 10px;
    transition: all .3s ease-in-out;
    border-radius: 50%;
    border: 1px solid #5f5f5f;
    box-shadow: 0px 0px 20px -3px rgb(221, 221, 221);
}
.seller_img img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transition: all .3s ease-in-out;
    background-color: rgb(209, 209, 209);
}
.slick-prev:before,
.slick-next:before {
    color: #5f5f5f !important;
    font-size: 20px !important;
    display: block !important;
}

.slick-prev:hover:before,
.slick-next:hover:before {
    color: #474747;
    font-size: 20px !important;
}

.slick-prev:focus:before,
.slick-next:focus:before {
    color: #474747;
    font-size: 20px !important;
}

