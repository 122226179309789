.hero_sec {
    width: 100%;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
}

/*.hero_sec::before {
    content: "";
    position: absolute;
    left: 0;
    top: -40px;
    width: 100%;
    height: 100%;
    background: linear-gradient(102.32deg, #6e42c13c 4.96%, #6f42c1 95.49%);
    z-index: -1;
    transform: skewY(-6deg);
    transform-origin: top left;
}*/

.hero_info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.hero_info h3 {
    font-size: 50px;
    color: /*#6f42c1*/ white;
}

.hero_info span {
    font-size: 30px;
    color: /*#5f5f5f*/ white;
    margin-top: -40px;
}

.div_download {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.div_download img {
    margin-right: 15px;
    cursor: pointer;
}
.img_download{
    width: 180px !important;
    height: 60px !important;
}
/*@media screen and (max-width: 1142px) {
    .hero_sec {
        padding: 30px 70px;
    }
}
@media screen and (max-width: 1000px) {
    .hero_img {
        width: 600px;
    }
}
@media screen and (max-width: 942px) {
    .hero_sec {
        padding: 30px 50px;
    }
}

@media screen and (max-width: 896px) {
    .hero_sec {
        flex-direction: column;
        height: auto;
        align-items: center;
        padding: 20px 0px;
        height: 80vh;
    }

    .hero_info {
        width: 100%;
        margin-right: 0px;
    }
    .hero_img {
        width: 320px;
        height: 50%;
    }
    .hero_info{
        width: 100%;
        margin-top: -500px;
    }
    .hero_info h3 {
        font-size: 35px;
    }

    .hero_info span {
        font-size: 25px;
    }
}
@media screen and (max-width: 465px) {
    .hero_img {
        width: 300px;
    }

    .div_download {
        flex-direction: column;
    }

    .div_download img {
        margin-bottom: 10px;
    }
    .hero_info{
        margin-top: -450px;
    }
}*/
@media screen and (max-width: 487px) {
    .div_download {
        flex-direction: column;
    }

    .div_download img {
        margin-bottom: 10px;
    }
}

.go-down {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.go-down i {
    animation: bouncing 1.5s infinite;
    cursor: pointer;
}

@keyframes bouncing {
    0%,
    10%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40%,
    60% {
        transform: translateY(-15px);
    }
}

@media screen and (max-width: 760px) {
    .hero_sec{
        height: 70vh;
    }
}