  .paginationBttns {
    width: 100%;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  
  .paginationBttns a {
    padding: 5px;
    margin: 8px;
    border-radius: 5px;
    color: gray;
    cursor: pointer;
  }
  
  .paginationBttns a:hover {
    color: white;
    background-color: #6f42c1;
    box-shadow: 0px 0px 25px -2px rgba(193, 193, 193, 0.83);
  }
  
  .paginationActive a {
    color: white;
    background-color: #6f42c1;
    box-shadow: 0px 0px 25px -2px rgba(193, 193, 193, 0.83);
  }
  
  .paginationDisabled a {
    display: none;
  }
  