.GeneralContainerContact {
    width: 90%;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    padding: 10px 40px;
}

.contact_info {
    width: 48%;
    padding: 10px 20px 40px 20px;
    background-color: transparent;
    /*box-shadow: 0px 0px 20px -3px rgb(221, 221, 221);*/
    border-radius: 10px;
}
.contact_info h3{
    font-size: 25px;
    color: #5f5f5f;
}
.contact_info h4{
    font-size: 19px;
    display: flex;
    align-items: center;
    color: #6f42c1;
}
.contact_info h4 i{
    margin-left: 10px;
    font-size: 25px;
}
.contact_tit{
    font-size: 17px;
    color: #5f5f5f;
}

.contact_map {
    width: 45%;
    height: 450px;
    background-color: rgb(222, 221, 221)
}
.contact_head{
    display: flex;
    align-items: center;
}
.contact_head img{
    width: 40px;
    height: 40px;
    margin-left: 15px;
}
.contact_head i{
    margin-left: 10px;
    font-size: 23px;
    color: #6f42c1;
}
.div_line{
    margin-top: -13px;
}
.div_line span{
    color: #5f5f5f;
    font-size: 17px;
}
.note{
    color: red;
    font-size: 19px;
}
@media screen and (max-width: 945px) {
    .contact_info{
        width: 100%;
        margin-bottom: 40px;
    }
    .contact_map{
        width: 100%;
    }
}