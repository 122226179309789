/********** header **************/
.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 10px 150px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
    /*box-shadow: 0 .5rem .5rem rgba(182, 181, 181, 0.1);*/
    height: 55px;
}
.scroll{
    background: rgba(255, 255, 255, 0.882) !important;
    box-shadow: 0 .5rem .5rem rgba(182, 181, 181, 0.1);
}
.unScroll{
    background: transparent !important;
    padding: 25px 150px !important;
}
.logo {
    width: 100px;
    height: 100%;
    padding: 3px;
    border-radius: 10px;
}
.logoScroll{
    background-color: rgba(255, 255, 255, .5);
}
.logoUnScroll{
    background-color: transparent;
}

.navbar{
    display: flex;

}

.navbar_linknew {
    font-size: 19px;
    padding: 0 1.2rem;
    transition: all 0.4s ease-in-out;
    text-decoration: none;
    font-weight: bold;
}
.navbar_linknewScroll{
    color: white;
}
.navbar_linknewunScroll{
    color: #6f42c1;
}
.navbar_linknew:hover{
    background-color: #6f42c1;
    color: white !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    border-radius: 50px;
}

.icons a {
    text-decoration: none;
    font-size: 22.4px;
    color: #be2edd;
    margin-left: 1.5rem;
    transition: all 0.4s ease-in-out;
    cursor: pointer;
}

.icons i {
    text-decoration: none;
    font-size: 22.4px;
    color: #be2edd;
    margin-left: 1.5rem;
    transition: all 0.4s ease-in-out;
    cursor: pointer;
}

.icons a:hover {
    transform: scale(1.1);
}

.icons i:hover {
    transform: scale(1.1);
}

#toggler {
    display: none;
}

.menu {
    font-size: 20px;
    color: rgb(170, 170, 170);
    border-radius: .5rem;
    padding: 5px 10px;
    cursor: pointer;
    border: 2px solid rgb(170, 170, 170);
    display: none;
}


/********** end header **************/
@media (max-width:1042px) {
    .navbar a {
        padding: 0 1rem;
    }
}

@media (max-width:840px) {
    .header {
        padding: 10px 50px !important;
    }

    .menu{
        display: block;
    }

    .navbar {
        height: 215px;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        /*background: red;*/
        box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .1);
        display: flex;
        flex-direction: column;
        border-top: .1rem solid rgba(0, 0, 0, .1);
        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    }
    .navbarScroll{
        background: rgba(255, 255, 255, 0.882) !important;
        color: rgb(170, 170, 170) !important;
    }
    .navbarunScroll{
        background-color: rgba(0, 0, 0, .5);
        color: white !important;
    }


    #toggler:checked~.navbar {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }


    .navbar_linknew{
        width: 100%;
        margin: 10px 0px;
        padding: 10px 0px;
        text-align: center;
        /*color: rgb(170, 170, 170) !important;*/
    }
    .navbar_linknew:hover{
        color: #6f42c1 !important;
        background-color: transparent;
        font-size: 22px;
    }

    .navbar_linknew:first-child{
        border-bottom: none;
    }
}