.div_row {
    display: flex;
    align-items: center;
    margin: 10px 0px;
}

.div_row img {
    margin-left: 15px;
    width: 25px;
    height: 25px;
}

.div_row i {
    width: 12%;
    font-size: 20px;
}

.div_row span {
    font-size: 16px;
    font-weight: 600;
}

.div_vendor {
    width: 370px;
    margin-bottom: 40px;
    border-radius: 20px !important;
    background-color: white;
    box-shadow: 0px 0px 20px -3px rgba(221, 221, 221, 0.808);
    border-radius: 5px;
    position: relative;
    cursor: pointer;
}
.colorrabs{
    position: absolute;
    color: white !important;
    border-radius: 20px;
    margin-bottom: 10px;
    background: linear-gradient(102.32deg, #d0baf9 4.96%, #6f42c1 95.49%);
    box-shadow: 0px 0px 20px -3px rgba(221, 221, 221, 0.808);
    width: 80%;
    height: 0%;
    top: 0px !important;
    overflow: auto;
    right: 20px !important;
    opacity: 0 !important;
    transition: 0.8s ease;
    margin: 10px auto;
    padding: 20px;
}
.div_vendor:hover .colorrabs{
    opacity: 1 !important;
    height: 80%;
}
.ven_tit {
    font-size: 22px;
    font-weight: 500;
}

.main_img {
    width: 100%;
    height: 250px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    background: linear-gradient(rgba(53, 37, 37, 0.2), rgba(0, 0, 0, 0.2)), url(./../../Images/logoH.png) center/cover no-repeat;
    background-size: contain;
    background-position: center;
    /*border-top-right-radius: 80px;
    border-bottom-left-radius: 80px;
    box-shadow: 0px 0px 20px -3px rgb(221, 221, 221);*/
}

.info_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 10px;
}

.info_vendoer {
    padding: 10px 30px;
    display: flex;
    flex-direction: column;
}

.ven_desc {
    font-size: 18px;
    margin-bottom: 8px;
}

.margin_minn {
    margin-top: -15px;
}

.stars_ven {
    display: flex;
    align-items: center;
}

.stars_ven i {
    margin-right: 5px;
    color: #6f42c1 !important;
}

.mapmap {
    color: white;
    margin-top: 3px;
    cursor: pointer;
    font-weight: bold;
    margin-bottom: 10px;
}

.ven_tit {
    color: #5f5f5f;
}

@media screen and (max-width: 1395px) {
    .div_vendor {
        width: 340px;
    }
}

@media screen and (max-width: 1280px) {
    .div_vendor {
        width: 370px;
    }
}

@media screen and (max-width: 1254px) {
    .div_vendor {
        width: 320px;
    }
}

@media screen and (max-width: 1120px) {
    .div_vendor {
        width: 280px;
    }
}

@media screen and (max-width: 945px) {
    .div_vendor {
        width: 310px;
    }
}

@media screen and (max-width: 851px) {
    .div_vendor {
        width: 285px;
    }
}

@media screen and (max-width: 729px) {
    .div_vendor {
        width: 360px;
    }
}

.popup {
    width: 950px;
    height: 650px;
    background-color: white;
    padding: 0px !important;
    margin: 0px !important;
    overflow: hidden;
    border-radius: 10px;
}

.div_arrow {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.div_arrow i {
    font-size: 19px;
    color: gray;
}

.colorr {
    color: #5f5f5f !important;
    margin-bottom: 10px;
}
.btn_ven{
    margin: 15px 0px;
    border: none;
    padding: 5px 20px;
    background-color: white;
    color: #6f42c1;
    border-radius: 50px;
    font-size: 16px;
    cursor: pointer;
    transition: all .3s ease-in-out;
}
.btn_ven i{
    margin-left: 10px;
    font-size: 18px !important;
}
.btn_ven:hover{
    transform: scale(1.09);
}

@media screen and (max-width: 863px) {
    .popup {
        width: 700px;
        height: 500px;
    }
}

@media screen and (max-width: 732px) {
    .popup {
        width: 550px;
        height: 400px;
    }
}

@media screen and (max-width: 583px) {
    .popup {
        width: 400px;
        height: 400px;
    }
}

@media screen and (max-width: 431px) {
    .popup {
        width: 300px;
        height: 300px;
    }
}

.search_ven {
    width: 500px;
    padding: 7px 12px;
    margin-bottom: 25px;
    border: 1px solid gray;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.with_input{
    display: flex;
    align-items: center;
    width: 95%;
}
.search_ven .iconSearch {
    font-size: 18px;
    color: #6f42c1;
    padding-left: 10px;
    border-left: 1px solid gray;
    cursor: pointer;
}
.icon_close{
    font-size: 18px;
    color: black;
    padding-left: 10px;
    margin-right: 20px;
    cursor: pointer;
}
.search_ven input {
    width: 100%;
    height: 100%;
    margin-right: 10px;
    border: none;
    outline: none;
    font-size: 16.5x;
    background-color: transparent;
}

.search_ven:hover {
    border: 1px solid #6f42c1;
    box-shadow: 0px 0px 20px -3px rgb(221, 221, 221);
}

@media screen and (max-width: 621px) {
    .search_ven {
        width: 350px;
    }
}

@media screen and (max-width: 422px) {
    .search_ven {
        width: 250px;
    }
}






.oneCardVen{
    display: flex;
    align-items: center;
    width: 47%;
    margin-bottom: 30px;
}
.oneCardVen_img{
    width: 380px;
    height: 380px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px -3px rgba(221, 221, 221, 0.808);
}
.oneCardVen_img img{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: linear-gradient(rgba(53, 37, 37, 0.2), rgba(0, 0, 0, 0.2)), url(./../../Images/logoH.png) center/cover no-repeat;
    background-size: contain;
    background-position: center;
}
.oneCardVen_info{
    background-color: white;
    box-shadow: 0px 0px 20px -3px rgba(221, 221, 221, 0.808);
    width: 60%;
    padding: 20px;
    margin-right: 20px;
    border-top-right-radius: 70px;
    border-bottom-left-radius: 70px;
}

@media screen and (max-width: 1068px) {
    .oneCardVen{
        width: 90%;
    }
}

@media screen and (max-width: 735px) {
    .oneCardVen{
        width: 100%;
    }
}

@media screen and (max-width: 535px) {
    .oneCardVen{
        width: 90%;
        flex-direction: column;
        background-color: white;
        box-shadow: 0px 0px 20px -3px rgba(221, 221, 221, 0.808);
    }
    .oneCardVen_img{
        width: 100%;
        height: 300px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        box-shadow: none;
    }
    .oneCardVen_img img{
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    .oneCardVen_info{
        width: 90%;
        box-shadow: none;
        border-radius: 0px !important;
        padding: 0px;
        margin-right: 0px;
    }
}





.newVen{
    width: 22%;
    background-color: white;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    margin-bottom: 40px;
    margin-left: 30px;
}
.newVen img{
    width: 100%;
    height: 170px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    background: linear-gradient(rgba(53, 37, 37, 0.2), rgba(0, 0, 0, 0.2)), url(./../../Images/logoH.png) center/cover no-repeat;
    background-size: contain;
    background-position: center;
}
.info_head11{
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    padding: 2px 20px;
    color: #5f5f5f;
}
.det_ven{
    width: 100%;
    padding: 0px 20px;
    margin-bottom: 20px;
    margin-top: -5px;
}
.det_ven button{
    outline: none;
    padding: 5px 20px;
    background-color: /*#083d54*/ #d3bff8;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 15px;
    border-top: 2px solid #6f42c1 ;
    border-right: 2px solid #6f42c1 ;
    border-left: none;
    border-bottom: none;
}
.det_ven button i{
    font-size: 18px !important;
    margin-left: 10px;
}
.img_noresult{
    width: 100%;
    display: flex;
    flex-direction: column;
    color: #5f5f5f;
    align-items: center;
    justify-content: center;
}
.img_noresult img{
    width: 300px;
    height: 300px;
}
@media screen and (max-width: 1120px) {
    .newVen{
        width: 28%;
    }
}
@media screen and (max-width: 795px) {
    .newVen{
        width: 43%;
    }
}
@media screen and (max-width: 551px) {
    .newVen{
        width: 80%;
        margin-left: 0px;
    }
}
@media screen and (max-width: 363px) {
    .newVen{
        width: 90%;
    }
}




