/*--------------about--------*/
.top {
    margin-top: 30px;
}

.mtop {
    margin-top: 5%;
}

.left,
.right {
    width: 50%;
}

.aboutt {
    margin-bottom: 30px;
}

.aboutt .img {
    position: relative;
}

.aboutt .image1 {
    width: 320px;
    height: 450px;
    box-shadow: 0px 0px 25px -5px rgb(231, 231, 231);
    background-color: white;
    border-radius: 10px;
}

.aboutt .image2 {
    width: 325px;
    height: 220px;
    position: absolute;
    bottom: 5px;
    z-index: 2;
    right: 30%;
    background-color: white;
    box-shadow: 0px 0px 25px -5px rgb(201, 201, 201);
    border-radius: 10px;
}

.heading {
    position: relative;
}

.heading::after {
    position: absolute;
    top: 0;
    right: 0;
    content: '';
    width: 100px;
    height: 4px;
    background: #6f42c1;
}

.heading h5 {
    font-weight: 400;
    padding-top: 20px;
    color: #5f5f5f;
    font-size: 18px;
}

.heading h2 {
    font-size: 30px;
    font-weight: 400;
    margin: 20px 0 40px 0;
    /*color: #ffc107;*/
    color: #6f42c1;
}

.heading p {
    margin-bottom: 20px;
    line-height: 25px;
    color: #5f5f5f;
    margin: 0 0 20px 50px;
    font-size: 19px;
}

.heading .btn1 {
    margin: 50px 0 20px 50px;
}

.btn1 {
    background: #C1B086;
    color: white;
}

.mark {
    display: flex;
    align-items: center;
}

.mark img {
    width: 50px;
    height: 50px;
    margin-top: -20px;
    margin-right: 5px;
}
.div_about{
    width: 100%;
    background-color: transparent;
    margin-top: 20px;
}
/*--------------about--------*/

@media screen and (max-width: 1095px) {
    .left,
    .right {
        width: 100%;
    }
}
@media screen and (max-width: 545px) {
    .img{
        margin-left: 30%;
    }
}
@media screen and (max-width: 463px) {
    .aboutt .image1 {
        width: 270px;
        height: 400px;
        box-shadow: 0px 0px 25px -5px rgb(185, 185, 185);
    }
    
    .aboutt .image2 {
        width: 270px;
        height: 200px;
        position: absolute;
        bottom: 5px;
        z-index: 2;
        right: 30%;
    }
}