.wave{
    width: 100%;
    background-color: red;
}
.application{
    width: 100%;
    background-color: white;
    /*box-shadow: 0px 0px 30px -5px rgb(218, 218, 218);*/
    margin-top: 50px;
    height: 400px;
    position: relative;
}

.inner_app{
    width: 80%;
   /* background-color: blue;*/
    display: flex;
    margin: 0px auto;
    height: 360px;
    padding-top: 15px;
}
.application_left{
    width: 40%;
   /* background-color: red;*/
}
.application_left img{
    height: 100%;
}
.application_right{
    width: 60%;
    display: flex;
    flex-direction: column;
}
.application_right h2{
    color: #6f42c1;
    margin-top: 100px;
    font-size: 35px ;
    letter-spacing: 1px;
}
@media screen and (max-width: 786px) {
    .application_left{
        display: none;
    }
    .application_right{
        width: 100%;
    }
}
@media screen and (max-width: 433px) {
    .application_right h2{
        font-size: 28px;
    }
}