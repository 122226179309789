.footer{
    width: 100%;
    padding: 65px 0px 10px 0px;
    background-color: white;
}
.FadeFooter{
    background-color: white;
}
.footer a{
    text-decoration: none;
}
.footer_row{
    display: flex;
    flex-wrap: wrap;
}
.one_footer{
    width: 24%;
    margin-right: 10px;
}
.one_footer h3{
    font-size: 22px;
    color: #5f5f5f;
}
.footer_line{
    width: 60px;
    height: 2.5px;
    background-color: #6f42c1;
    margin-top: -15px;
}
.div_link{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}
.div_link span , .footer_link{
    margin-bottom: 7px;
    transition: all .3s ease-in-out;
    color: #838383;
    font-size: 17.5px;
}
.footer_link{
    cursor: pointer;
}
.footer_link:hover{
    margin-right: 10px;
    color: #6f42c1;
    font-weight: bold;
}
.num_footer{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.num_footer i{
    font-size: 20px;
    margin: 0px 10px;
    color: #6f42c1;
}
.num_footer span{
    margin-bottom: 0px !important;
}
.powered{
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5f5f5f;
}
.powered img{
    width: 30px;
    height: 30px;
    margin: 0px 10px;
}

@media screen and (max-width: 1116px) {
    .one_footer{
        width: 30%;
        margin-bottom: 15px;
    }
}
@media screen and (max-width: 850px) {
    .one_footer{
        width: 48%;
    }
}
@media screen and (max-width: 630px) {
    .one_footer{
        width: 90%;
    }
}