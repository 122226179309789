.LoadingPage{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.LoadingPage img{
    width: 400px;
    height: 400px;
}