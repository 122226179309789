.oneFaq {
    width: 45%;
    border-radius: 10px;
    margin-left: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    padding: 20px;
}
.oneFaq img{
    width: 100px;
    height: 100px;
}
.oneFaq .ques_info{
    width: 100%;
    padding: 10px 25px;
}
.question{
    font-size: 19px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #6f42c1;
}
.answer{
    color: #5f5f5f;
    font-size: 17px;
}
.titleFaq{
    font-size: 30px;
    color: #6f42c1;
    margin-bottom: 35px;
}
@media screen and (max-width: 1500px) {
    .oneFaq{
        width: 43%;
    }
}
@media screen and (max-width: 981px) {
    .oneFaq{
        width: 90%;
    }
}
@media screen and (max-width: 640px) {
    .oneFaq{
        width: 100%;
        margin-left: 0px;
    }
}
@media screen and (max-width: 540px) {
    .oneFaq{
        flex-direction: column;
    }
    .oneFaq img{
        margin-bottom: 10px;
    }
}