/*@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');*/

/*@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@300&family=Open+Sans&family=Oswald:wght@300&family=Roboto+Condensed&family=Roboto:wght@300&family=Tilt+Neon&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
/*@import url('https://fonts.googleapis.com/css2?family=Amiri&family=Cairo:wght@300&family=Cormorant:wght@300&family=Open+Sans&family=Oswald:wght@300&family=Roboto+Condensed&family=Roboto:wght@300&family=Schibsted+Grotesk&family=Tilt+Neon&display=swap');*/

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  /*font-family: 'Amiri', serif;-*/
  /*Almarai*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: /*#eeeded93*/ #eeeded93;
  position: relative;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #cdcdcd;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #cdcdcd;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #cdcdcd;
}
.GeneralContainer1{
  width: 80%;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}

.GeneralContainer{
  width: 85%;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}
.margin_bottom{
  margin-bottom: 0px !important;
}

.header_title{
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 7px;
  color: #1A2B48;
}
.header_desc{
  color: gray;
}

.GeneralRow{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  justify-content: space-between;
}
.GeneralRowVen{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}
.align_item{
  align-items: flex-start;
}
@media screen and (max-width: 1370px) {
  .GeneralContainer{
    width: 90%;
  }
}


.allItem{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.allItem button{
  padding: 10px 28px;
  border: none;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: 0px 0px 21px -1px rgba(185, 185, 185, 0.795);
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.4s ease-in-out;
  background-color: #243c64;
  color: white;
}
.allItem button:hover{
  background-color: #476aa7;
  transform: scale(1.05);
}
.div_loader{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}
.color{
  color: #6f42c1;
}


