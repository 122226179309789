.card_details{
    background-color: white !important;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 40px;
}
.detailsTop{
    padding: 20px 40px 20px 40px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(216, 216, 216, 0.592);
}
.detailsTop img{
    width: 50px;
    height: 50px;
    margin-left: 13px;
    border-radius: 50%;
}
.tit_det{
    font-size: 25px;
    font-weight: bold;
    color: #5f5f5f;
}
.span_det{
    color: #5f5f5f;
    font-size: 17px;
}
.more_info_brand{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 20px;
}
.info_brand{
    width: 50%;
    background-color: white;
    box-shadow: 0px 0px 20px -3px rgba(221, 221, 221, 0.808);
    padding: 20px;
    color: #5f5f5f;
    border-radius: 10px;
}
.map_brad{
    width: 40%;
    background-color: rgba(216, 216, 216, 0.716);
    height: 400px;
}
.brand_header_flex{
    display: flex;
    align-items: center;
}
.brand_header_flex h3{
    color: #6f42c1 !important;
}
.brand_header_flex img{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-left: 15px;
    box-shadow: 0px 0px 25px -5px rgb(205, 205, 205);
    background: linear-gradient(rgba(53, 37, 37, 0.2), rgba(0, 0, 0, 0.2)), url(./../../Images/logoH.png) center/cover no-repeat;
    background-size: contain;
    background-position: center;
}
.rest_info{
    margin: 15px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.rest_info p{
    font-size: 18px;
}
.icon_flex{
    display: flex;
    align-items: center;
}
.icon_flex i{
    font-size: 17px;
    margin-left: 15px;
    color: #6f42c1;
}
.icon_flex img{
    width: 30px;
    height: 30px;
}
.price_product{
    font-size: 17px !important;
}
.color_red{
    color: red !important;
}
.color_yellow{
    color: #FFCE26 !important;
}
.nopProduct{
    width: 100%;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 30px 0px;
}
.nopProduct img{
    width: 80px;
    height: 80px;
}
.nopProduct span{
    color: #5f5f5f;
    margin-top: 5px;
}
@media screen and (max-width: 939px) {
    .more_info_brand{
        flex-direction: column;
    }
    .info_brand{
        width: 92%;
        margin-bottom: 40px;
    }
    .map_brad{
        width: 100%;
    }
}