.more_know{
    font-size: 30px;
    color: #6f42c1;
    margin-bottom: 30px;
}
.cards{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.oneCard{
    width: 19%;
    background-color: white;
    box-shadow: 0px 0px 20px -3px rgb(221, 221, 221);
    margin-bottom: 35px;
    display: flex;
    flex-direction: column;
    padding:  20px;
    border-radius: 5px;
    cursor: pointer;
    transition: all .3s ease-in-out;
}
.oneCard:hover{
    margin-top: -15px;
}
.div_img{
    display: flex;
    justify-content: flex-end;
    margin: 20px 0px 15px 0px;
    background-color: none;
}
.oneCard img{
    width: 70px;
    height: 70px;
    text-align: left;
    transition: all .3s ease-in-out;
}

.oneCard h3{
    color: #5f5f5f;
    font-size: 22px;
}
.oneCard span{
    color: gray;
    margin-top: -5px;
}
.border_blue{
    border-top: 2.2px solid rgb(36, 101, 185);
}
.border_pink{
    border-top: 2.2px solid rgb(213, 4, 70);
}
.border_green{
    border-top: 2.2px solid #2AD2B6;
}
.border_orang{
    border-top: 2.2px solid rgb(248, 130, 71);
}
.border_yellow{
    border-top: 2.2px solid #ffc107;
}
@media screen and (max-width: 1117px) {
    .oneCard{
        width: 24%;
    }
}
@media screen and (max-width: 881px) {
    .oneCard{
        width: 38%;
    }
}
@media screen and (max-width: 666px) {
    .oneCard{
        width: 95%;
    }
}