.mainbox {
    margin: 100px auto;
    height: 600px;
    width: 600px;
    position: relative;
}

.err {
    color: #6f42c1;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 11rem;
    position: absolute;
    left: 20%;
    top: 8%;
}

.far {
    position: absolute;
    font-size: 8.5rem;
    left: 42%;
    top: 15%;
    color: #6f42c1;
}

.err2 {
    color: #6f42c1;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 11rem;
    position: absolute;
    left: 68%;
    top: 8%;
}

.msg {
    text-align: center;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 1.6rem;
    position: absolute;
    left: 16%;
    top: 45%;
    width: 75%;
    color: #5f5f5f;
}
@media screen and (max-width: 645px) {
    .mainbox{
        background-color: red;
        width: 400px;
    }
    .err,.err2{
        font-size: 9rem;
    }
    .far{
        font-size: 6rem;
    }
}
@media screen and (max-width: 420px) {
    .mainbox{
        background-color: red;
        width: 300px;
    }
    .err,.err2{
        font-size: 7.5rem;
    }
    .far{
        font-size: 4.5rem;
    }
}