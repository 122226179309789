.onePrivacy{
    width: 100%;
    margin-bottom: 20px;
    padding: 25px 40px 25px 40px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 0px 20px -3px rgb(221, 221, 221);
}
.privacy_head{
    display: flex;
    align-items: center;
}
.privacy_head i{
    font-size: 18px;
    background-color: rgb(36, 101, 185);
    color: white;
    padding: 10px;
    border-radius: 50%;
}
.onePrivacy_tit{
    font-size: 22px;
    font-weight: bold;
    margin-right: 10px;
    color: #484747;
}
.onePrivacy h5{
    font-size: 18px;
    color: #5f5f5f;
}
.onePrivacy p{
    font-size: 17px;
    line-height: 1.6;
    color: #5f5f5f;
}
.onePrivacy img{
    width: 90px;
    height: 90px;
    float: left;
}
