@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
/*@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');*/

/*@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@300&family=Open+Sans&family=Oswald:wght@300&family=Roboto+Condensed&family=Roboto:wght@300&family=Tilt+Neon&display=swap');*/
/*@import url('https://fonts.googleapis.com/css2?family=Amiri&family=Cairo:wght@300&family=Cormorant:wght@300&family=Open+Sans&family=Oswald:wght@300&family=Roboto+Condensed&family=Roboto:wght@300&family=Schibsted+Grotesk&family=Tilt+Neon&display=swap');*/

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  /*font-family: 'Amiri', serif;-*/
  /*Almarai*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: /*#eeeded93*/ #eeeded93;
  position: relative;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #cdcdcd;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #cdcdcd;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #cdcdcd;
}
.GeneralContainer1{
  width: 80%;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}

.GeneralContainer{
  width: 85%;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}
.margin_bottom{
  margin-bottom: 0px !important;
}

.header_title{
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 7px;
  color: #1A2B48;
}
.header_desc{
  color: gray;
}

.GeneralRow{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  justify-content: space-between;
}
.GeneralRowVen{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}
.align_item{
  align-items: flex-start;
}
@media screen and (max-width: 1370px) {
  .GeneralContainer{
    width: 90%;
  }
}


.allItem{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.allItem button{
  padding: 10px 28px;
  border: none;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: 0px 0px 21px -1px rgba(185, 185, 185, 0.795);
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.4s ease-in-out;
  background-color: #243c64;
  color: white;
}
.allItem button:hover{
  background-color: #476aa7;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
.div_loader{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}
.color{
  color: #6f42c1;
}



/*.text_center{
    text-align: center;
}
.container{
    height: 100vh;
}*/
/********** header **************/
.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 10px 150px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
    /*box-shadow: 0 .5rem .5rem rgba(182, 181, 181, 0.1);*/
    height: 55px;
}
.scroll{
    background: rgba(255, 255, 255, 0.882) !important;
    box-shadow: 0 .5rem .5rem rgba(182, 181, 181, 0.1);
}
.unScroll{
    background: transparent !important;
    padding: 25px 150px !important;
}
.logo {
    width: 100px;
    height: 100%;
    padding: 3px;
    border-radius: 10px;
}
.logoScroll{
    background-color: rgba(255, 255, 255, .5);
}
.logoUnScroll{
    background-color: transparent;
}

.navbar{
    display: flex;

}

.navbar_linknew {
    font-size: 19px;
    padding: 0 1.2rem;
    transition: all 0.4s ease-in-out;
    text-decoration: none;
    font-weight: bold;
}
.navbar_linknewScroll{
    color: white;
}
.navbar_linknewunScroll{
    color: #6f42c1;
}
.navbar_linknew:hover{
    background-color: #6f42c1;
    color: white !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    border-radius: 50px;
}

.icons a {
    text-decoration: none;
    font-size: 22.4px;
    color: #be2edd;
    margin-left: 1.5rem;
    transition: all 0.4s ease-in-out;
    cursor: pointer;
}

.icons i {
    text-decoration: none;
    font-size: 22.4px;
    color: #be2edd;
    margin-left: 1.5rem;
    transition: all 0.4s ease-in-out;
    cursor: pointer;
}

.icons a:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.icons i:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

#toggler {
    display: none;
}

.menu {
    font-size: 20px;
    color: rgb(170, 170, 170);
    border-radius: .5rem;
    padding: 5px 10px;
    cursor: pointer;
    border: 2px solid rgb(170, 170, 170);
    display: none;
}


/********** end header **************/
@media (max-width:1042px) {
    .navbar a {
        padding: 0 1rem;
    }
}

@media (max-width:840px) {
    .header {
        padding: 10px 50px !important;
    }

    .menu{
        display: block;
    }

    .navbar {
        height: 215px;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        /*background: red;*/
        box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .1);
        display: flex;
        flex-direction: column;
        border-top: .1rem solid rgba(0, 0, 0, .1);
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
                clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    }
    .navbarScroll{
        background: rgba(255, 255, 255, 0.882) !important;
        color: rgb(170, 170, 170) !important;
    }
    .navbarunScroll{
        background-color: rgba(0, 0, 0, .5);
        color: white !important;
    }


    #toggler:checked~.navbar {
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }


    .navbar_linknew{
        width: 100%;
        margin: 10px 0px;
        padding: 10px 0px;
        text-align: center;
        /*color: rgb(170, 170, 170) !important;*/
    }
    .navbar_linknew:hover{
        color: #6f42c1 !important;
        background-color: transparent;
        font-size: 22px;
    }

    .navbar_linknew:first-child{
        border-bottom: none;
    }
}
.hero_sec {
    width: 100%;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
}

/*.hero_sec::before {
    content: "";
    position: absolute;
    left: 0;
    top: -40px;
    width: 100%;
    height: 100%;
    background: linear-gradient(102.32deg, #6e42c13c 4.96%, #6f42c1 95.49%);
    z-index: -1;
    transform: skewY(-6deg);
    transform-origin: top left;
}*/

.hero_info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.hero_info h3 {
    font-size: 50px;
    color: /*#6f42c1*/ white;
}

.hero_info span {
    font-size: 30px;
    color: /*#5f5f5f*/ white;
    margin-top: -40px;
}

.div_download {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.div_download img {
    margin-right: 15px;
    cursor: pointer;
}
.img_download{
    width: 180px !important;
    height: 60px !important;
}
/*@media screen and (max-width: 1142px) {
    .hero_sec {
        padding: 30px 70px;
    }
}
@media screen and (max-width: 1000px) {
    .hero_img {
        width: 600px;
    }
}
@media screen and (max-width: 942px) {
    .hero_sec {
        padding: 30px 50px;
    }
}

@media screen and (max-width: 896px) {
    .hero_sec {
        flex-direction: column;
        height: auto;
        align-items: center;
        padding: 20px 0px;
        height: 80vh;
    }

    .hero_info {
        width: 100%;
        margin-right: 0px;
    }
    .hero_img {
        width: 320px;
        height: 50%;
    }
    .hero_info{
        width: 100%;
        margin-top: -500px;
    }
    .hero_info h3 {
        font-size: 35px;
    }

    .hero_info span {
        font-size: 25px;
    }
}
@media screen and (max-width: 465px) {
    .hero_img {
        width: 300px;
    }

    .div_download {
        flex-direction: column;
    }

    .div_download img {
        margin-bottom: 10px;
    }
    .hero_info{
        margin-top: -450px;
    }
}*/
@media screen and (max-width: 487px) {
    .div_download {
        flex-direction: column;
    }

    .div_download img {
        margin-bottom: 10px;
    }
}

.go-down {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.go-down i {
    -webkit-animation: bouncing 1.5s infinite;
            animation: bouncing 1.5s infinite;
    cursor: pointer;
}

@-webkit-keyframes bouncing {
    0%,
    10%,
    20%,
    50%,
    80%,
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }

    40%,
    60% {
        -webkit-transform: translateY(-15px);
                transform: translateY(-15px);
    }
}

@keyframes bouncing {
    0%,
    10%,
    20%,
    50%,
    80%,
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }

    40%,
    60% {
        -webkit-transform: translateY(-15px);
                transform: translateY(-15px);
    }
}

@media screen and (max-width: 760px) {
    .hero_sec{
        height: 70vh;
    }
}
/*--------------about--------*/
.top {
    margin-top: 30px;
}

.mtop {
    margin-top: 5%;
}

.left,
.right {
    width: 50%;
}

.aboutt {
    margin-bottom: 30px;
}

.aboutt .img {
    position: relative;
}

.aboutt .image1 {
    width: 320px;
    height: 450px;
    box-shadow: 0px 0px 25px -5px rgb(231, 231, 231);
    background-color: white;
    border-radius: 10px;
}

.aboutt .image2 {
    width: 325px;
    height: 220px;
    position: absolute;
    bottom: 5px;
    z-index: 2;
    right: 30%;
    background-color: white;
    box-shadow: 0px 0px 25px -5px rgb(201, 201, 201);
    border-radius: 10px;
}

.heading {
    position: relative;
}

.heading::after {
    position: absolute;
    top: 0;
    right: 0;
    content: '';
    width: 100px;
    height: 4px;
    background: #6f42c1;
}

.heading h5 {
    font-weight: 400;
    padding-top: 20px;
    color: #5f5f5f;
    font-size: 18px;
}

.heading h2 {
    font-size: 30px;
    font-weight: 400;
    margin: 20px 0 40px 0;
    /*color: #ffc107;*/
    color: #6f42c1;
}

.heading p {
    margin-bottom: 20px;
    line-height: 25px;
    color: #5f5f5f;
    margin: 0 0 20px 50px;
    font-size: 19px;
}

.heading .btn1 {
    margin: 50px 0 20px 50px;
}

.btn1 {
    background: #C1B086;
    color: white;
}

.mark {
    display: flex;
    align-items: center;
}

.mark img {
    width: 50px;
    height: 50px;
    margin-top: -20px;
    margin-right: 5px;
}
.div_about{
    width: 100%;
    background-color: transparent;
    margin-top: 20px;
}
/*--------------about--------*/

@media screen and (max-width: 1095px) {
    .left,
    .right {
        width: 100%;
    }
}
@media screen and (max-width: 545px) {
    .img{
        margin-left: 30%;
    }
}
@media screen and (max-width: 463px) {
    .aboutt .image1 {
        width: 270px;
        height: 400px;
        box-shadow: 0px 0px 25px -5px rgb(185, 185, 185);
    }
    
    .aboutt .image2 {
        width: 270px;
        height: 200px;
        position: absolute;
        bottom: 5px;
        z-index: 2;
        right: 30%;
    }
}
.more_know{
    font-size: 30px;
    color: #6f42c1;
    margin-bottom: 30px;
}
.cards{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.oneCard{
    width: 19%;
    background-color: white;
    box-shadow: 0px 0px 20px -3px rgb(221, 221, 221);
    margin-bottom: 35px;
    display: flex;
    flex-direction: column;
    padding:  20px;
    border-radius: 5px;
    cursor: pointer;
    transition: all .3s ease-in-out;
}
.oneCard:hover{
    margin-top: -15px;
}
.div_img{
    display: flex;
    justify-content: flex-end;
    margin: 20px 0px 15px 0px;
    background-color: none;
}
.oneCard img{
    width: 70px;
    height: 70px;
    text-align: left;
    transition: all .3s ease-in-out;
}

.oneCard h3{
    color: #5f5f5f;
    font-size: 22px;
}
.oneCard span{
    color: gray;
    margin-top: -5px;
}
.border_blue{
    border-top: 2.2px solid rgb(36, 101, 185);
}
.border_pink{
    border-top: 2.2px solid rgb(213, 4, 70);
}
.border_green{
    border-top: 2.2px solid #2AD2B6;
}
.border_orang{
    border-top: 2.2px solid rgb(248, 130, 71);
}
.border_yellow{
    border-top: 2.2px solid #ffc107;
}
@media screen and (max-width: 1117px) {
    .oneCard{
        width: 24%;
    }
}
@media screen and (max-width: 881px) {
    .oneCard{
        width: 38%;
    }
}
@media screen and (max-width: 666px) {
    .oneCard{
        width: 95%;
    }
}
.oneSeller{
    width: 80% !important;
    background-color: transparent;
    padding: 10px ;
    margin: 20px !important;
    transition: all .3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}
.oneSeller h3{
    text-align: center;
    color: #5f5f5f;
    font-size: 22px;
}
.oneSeller:hover .oneSeller img{
    width: 100%;
    border-radius: 10px;
}
.center_img{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.seller_img{
    width: 160px;
    height: 160px;
    padding: 10px;
    transition: all .3s ease-in-out;
    border-radius: 50%;
    border: 1px solid #5f5f5f;
    box-shadow: 0px 0px 20px -3px rgb(221, 221, 221);
}
.seller_img img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transition: all .3s ease-in-out;
    background-color: rgb(209, 209, 209);
}
.slick-prev:before,
.slick-next:before {
    color: #5f5f5f !important;
    font-size: 20px !important;
    display: block !important;
}

.slick-prev:hover:before,
.slick-next:hover:before {
    color: #474747;
    font-size: 20px !important;
}

.slick-prev:focus:before,
.slick-next:focus:before {
    color: #474747;
    font-size: 20px !important;
}


.wave{
    width: 100%;
    background-color: red;
}
.application{
    width: 100%;
    background-color: white;
    /*box-shadow: 0px 0px 30px -5px rgb(218, 218, 218);*/
    margin-top: 50px;
    height: 400px;
    position: relative;
}

.inner_app{
    width: 80%;
   /* background-color: blue;*/
    display: flex;
    margin: 0px auto;
    height: 360px;
    padding-top: 15px;
}
.application_left{
    width: 40%;
   /* background-color: red;*/
}
.application_left img{
    height: 100%;
}
.application_right{
    width: 60%;
    display: flex;
    flex-direction: column;
}
.application_right h2{
    color: #6f42c1;
    margin-top: 100px;
    font-size: 35px ;
    letter-spacing: 1px;
}
@media screen and (max-width: 786px) {
    .application_left{
        display: none;
    }
    .application_right{
        width: 100%;
    }
}
@media screen and (max-width: 433px) {
    .application_right h2{
        font-size: 28px;
    }
}
.footer{
    width: 100%;
    padding: 65px 0px 10px 0px;
    background-color: white;
}
.FadeFooter{
    background-color: white;
}
.footer a{
    text-decoration: none;
}
.footer_row{
    display: flex;
    flex-wrap: wrap;
}
.one_footer{
    width: 24%;
    margin-right: 10px;
}
.one_footer h3{
    font-size: 22px;
    color: #5f5f5f;
}
.footer_line{
    width: 60px;
    height: 2.5px;
    background-color: #6f42c1;
    margin-top: -15px;
}
.div_link{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}
.div_link span , .footer_link{
    margin-bottom: 7px;
    transition: all .3s ease-in-out;
    color: #838383;
    font-size: 17.5px;
}
.footer_link{
    cursor: pointer;
}
.footer_link:hover{
    margin-right: 10px;
    color: #6f42c1;
    font-weight: bold;
}
.num_footer{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.num_footer i{
    font-size: 20px;
    margin: 0px 10px;
    color: #6f42c1;
}
.num_footer span{
    margin-bottom: 0px !important;
}
.powered{
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5f5f5f;
}
.powered img{
    width: 30px;
    height: 30px;
    margin: 0px 10px;
}

@media screen and (max-width: 1116px) {
    .one_footer{
        width: 30%;
        margin-bottom: 15px;
    }
}
@media screen and (max-width: 850px) {
    .one_footer{
        width: 48%;
    }
}
@media screen and (max-width: 630px) {
    .one_footer{
        width: 90%;
    }
}
.oneFaq {
    width: 45%;
    border-radius: 10px;
    margin-left: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    padding: 20px;
}
.oneFaq img{
    width: 100px;
    height: 100px;
}
.oneFaq .ques_info{
    width: 100%;
    padding: 10px 25px;
}
.question{
    font-size: 19px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #6f42c1;
}
.answer{
    color: #5f5f5f;
    font-size: 17px;
}
.titleFaq{
    font-size: 30px;
    color: #6f42c1;
    margin-bottom: 35px;
}
@media screen and (max-width: 1500px) {
    .oneFaq{
        width: 43%;
    }
}
@media screen and (max-width: 981px) {
    .oneFaq{
        width: 90%;
    }
}
@media screen and (max-width: 640px) {
    .oneFaq{
        width: 100%;
        margin-left: 0px;
    }
}
@media screen and (max-width: 540px) {
    .oneFaq{
        flex-direction: column;
    }
    .oneFaq img{
        margin-bottom: 10px;
    }
}
  .paginationBttns {
    width: 100%;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  
  .paginationBttns a {
    padding: 5px;
    margin: 8px;
    border-radius: 5px;
    color: gray;
    cursor: pointer;
  }
  
  .paginationBttns a:hover {
    color: white;
    background-color: #6f42c1;
    box-shadow: 0px 0px 25px -2px rgba(193, 193, 193, 0.83);
  }
  
  .paginationActive a {
    color: white;
    background-color: #6f42c1;
    box-shadow: 0px 0px 25px -2px rgba(193, 193, 193, 0.83);
  }
  
  .paginationDisabled a {
    display: none;
  }
  
.onePrivacy{
    width: 100%;
    margin-bottom: 20px;
    padding: 25px 40px 25px 40px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 0px 20px -3px rgb(221, 221, 221);
}
.privacy_head{
    display: flex;
    align-items: center;
}
.privacy_head i{
    font-size: 18px;
    background-color: rgb(36, 101, 185);
    color: white;
    padding: 10px;
    border-radius: 50%;
}
.onePrivacy_tit{
    font-size: 22px;
    font-weight: bold;
    margin-right: 10px;
    color: #484747;
}
.onePrivacy h5{
    font-size: 18px;
    color: #5f5f5f;
}
.onePrivacy p{
    font-size: 17px;
    line-height: 1.6;
    color: #5f5f5f;
}
.onePrivacy img{
    width: 90px;
    height: 90px;
    float: left;
}

.GeneralContainerContact {
    width: 90%;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    padding: 10px 40px;
}

.contact_info {
    width: 48%;
    padding: 10px 20px 40px 20px;
    background-color: transparent;
    /*box-shadow: 0px 0px 20px -3px rgb(221, 221, 221);*/
    border-radius: 10px;
}
.contact_info h3{
    font-size: 25px;
    color: #5f5f5f;
}
.contact_info h4{
    font-size: 19px;
    display: flex;
    align-items: center;
    color: #6f42c1;
}
.contact_info h4 i{
    margin-left: 10px;
    font-size: 25px;
}
.contact_tit{
    font-size: 17px;
    color: #5f5f5f;
}

.contact_map {
    width: 45%;
    height: 450px;
    background-color: rgb(222, 221, 221)
}
.contact_head{
    display: flex;
    align-items: center;
}
.contact_head img{
    width: 40px;
    height: 40px;
    margin-left: 15px;
}
.contact_head i{
    margin-left: 10px;
    font-size: 23px;
    color: #6f42c1;
}
.div_line{
    margin-top: -13px;
}
.div_line span{
    color: #5f5f5f;
    font-size: 17px;
}
.note{
    color: red;
    font-size: 19px;
}
@media screen and (max-width: 945px) {
    .contact_info{
        width: 100%;
        margin-bottom: 40px;
    }
    .contact_map{
        width: 100%;
    }
}
.div_row {
    display: flex;
    align-items: center;
    margin: 10px 0px;
}

.div_row img {
    margin-left: 15px;
    width: 25px;
    height: 25px;
}

.div_row i {
    width: 12%;
    font-size: 20px;
}

.div_row span {
    font-size: 16px;
    font-weight: 600;
}

.div_vendor {
    width: 370px;
    margin-bottom: 40px;
    border-radius: 20px !important;
    background-color: white;
    box-shadow: 0px 0px 20px -3px rgba(221, 221, 221, 0.808);
    border-radius: 5px;
    position: relative;
    cursor: pointer;
}
.colorrabs{
    position: absolute;
    color: white !important;
    border-radius: 20px;
    margin-bottom: 10px;
    background: linear-gradient(102.32deg, #d0baf9 4.96%, #6f42c1 95.49%);
    box-shadow: 0px 0px 20px -3px rgba(221, 221, 221, 0.808);
    width: 80%;
    height: 0%;
    top: 0px !important;
    overflow: auto;
    right: 20px !important;
    opacity: 0 !important;
    transition: 0.8s ease;
    margin: 10px auto;
    padding: 20px;
}
.div_vendor:hover .colorrabs{
    opacity: 1 !important;
    height: 80%;
}
.ven_tit {
    font-size: 22px;
    font-weight: 500;
}

.main_img {
    width: 100%;
    height: 250px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    background: linear-gradient(rgba(53, 37, 37, 0.2), rgba(0, 0, 0, 0.2)), url(/static/media/logoH.07ff0d2c.png) center/cover no-repeat;
    background-size: contain;
    background-position: center;
    /*border-top-right-radius: 80px;
    border-bottom-left-radius: 80px;
    box-shadow: 0px 0px 20px -3px rgb(221, 221, 221);*/
}

.info_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 10px;
}

.info_vendoer {
    padding: 10px 30px;
    display: flex;
    flex-direction: column;
}

.ven_desc {
    font-size: 18px;
    margin-bottom: 8px;
}

.margin_minn {
    margin-top: -15px;
}

.stars_ven {
    display: flex;
    align-items: center;
}

.stars_ven i {
    margin-right: 5px;
    color: #6f42c1 !important;
}

.mapmap {
    color: white;
    margin-top: 3px;
    cursor: pointer;
    font-weight: bold;
    margin-bottom: 10px;
}

.ven_tit {
    color: #5f5f5f;
}

@media screen and (max-width: 1395px) {
    .div_vendor {
        width: 340px;
    }
}

@media screen and (max-width: 1280px) {
    .div_vendor {
        width: 370px;
    }
}

@media screen and (max-width: 1254px) {
    .div_vendor {
        width: 320px;
    }
}

@media screen and (max-width: 1120px) {
    .div_vendor {
        width: 280px;
    }
}

@media screen and (max-width: 945px) {
    .div_vendor {
        width: 310px;
    }
}

@media screen and (max-width: 851px) {
    .div_vendor {
        width: 285px;
    }
}

@media screen and (max-width: 729px) {
    .div_vendor {
        width: 360px;
    }
}

.popup {
    width: 950px;
    height: 650px;
    background-color: white;
    padding: 0px !important;
    margin: 0px !important;
    overflow: hidden;
    border-radius: 10px;
}

.div_arrow {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.div_arrow i {
    font-size: 19px;
    color: gray;
}

.colorr {
    color: #5f5f5f !important;
    margin-bottom: 10px;
}
.btn_ven{
    margin: 15px 0px;
    border: none;
    padding: 5px 20px;
    background-color: white;
    color: #6f42c1;
    border-radius: 50px;
    font-size: 16px;
    cursor: pointer;
    transition: all .3s ease-in-out;
}
.btn_ven i{
    margin-left: 10px;
    font-size: 18px !important;
}
.btn_ven:hover{
    -webkit-transform: scale(1.09);
            transform: scale(1.09);
}

@media screen and (max-width: 863px) {
    .popup {
        width: 700px;
        height: 500px;
    }
}

@media screen and (max-width: 732px) {
    .popup {
        width: 550px;
        height: 400px;
    }
}

@media screen and (max-width: 583px) {
    .popup {
        width: 400px;
        height: 400px;
    }
}

@media screen and (max-width: 431px) {
    .popup {
        width: 300px;
        height: 300px;
    }
}

.search_ven {
    width: 500px;
    padding: 7px 12px;
    margin-bottom: 25px;
    border: 1px solid gray;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.with_input{
    display: flex;
    align-items: center;
    width: 95%;
}
.search_ven .iconSearch {
    font-size: 18px;
    color: #6f42c1;
    padding-left: 10px;
    border-left: 1px solid gray;
    cursor: pointer;
}
.icon_close{
    font-size: 18px;
    color: black;
    padding-left: 10px;
    margin-right: 20px;
    cursor: pointer;
}
.search_ven input {
    width: 100%;
    height: 100%;
    margin-right: 10px;
    border: none;
    outline: none;
    font-size: 16.5x;
    background-color: transparent;
}

.search_ven:hover {
    border: 1px solid #6f42c1;
    box-shadow: 0px 0px 20px -3px rgb(221, 221, 221);
}

@media screen and (max-width: 621px) {
    .search_ven {
        width: 350px;
    }
}

@media screen and (max-width: 422px) {
    .search_ven {
        width: 250px;
    }
}






.oneCardVen{
    display: flex;
    align-items: center;
    width: 47%;
    margin-bottom: 30px;
}
.oneCardVen_img{
    width: 380px;
    height: 380px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px -3px rgba(221, 221, 221, 0.808);
}
.oneCardVen_img img{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: linear-gradient(rgba(53, 37, 37, 0.2), rgba(0, 0, 0, 0.2)), url(/static/media/logoH.07ff0d2c.png) center/cover no-repeat;
    background-size: contain;
    background-position: center;
}
.oneCardVen_info{
    background-color: white;
    box-shadow: 0px 0px 20px -3px rgba(221, 221, 221, 0.808);
    width: 60%;
    padding: 20px;
    margin-right: 20px;
    border-top-right-radius: 70px;
    border-bottom-left-radius: 70px;
}

@media screen and (max-width: 1068px) {
    .oneCardVen{
        width: 90%;
    }
}

@media screen and (max-width: 735px) {
    .oneCardVen{
        width: 100%;
    }
}

@media screen and (max-width: 535px) {
    .oneCardVen{
        width: 90%;
        flex-direction: column;
        background-color: white;
        box-shadow: 0px 0px 20px -3px rgba(221, 221, 221, 0.808);
    }
    .oneCardVen_img{
        width: 100%;
        height: 300px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        box-shadow: none;
    }
    .oneCardVen_img img{
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    .oneCardVen_info{
        width: 90%;
        box-shadow: none;
        border-radius: 0px !important;
        padding: 0px;
        margin-right: 0px;
    }
}





.newVen{
    width: 22%;
    background-color: white;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    margin-bottom: 40px;
    margin-left: 30px;
}
.newVen img{
    width: 100%;
    height: 170px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    background: linear-gradient(rgba(53, 37, 37, 0.2), rgba(0, 0, 0, 0.2)), url(/static/media/logoH.07ff0d2c.png) center/cover no-repeat;
    background-size: contain;
    background-position: center;
}
.info_head11{
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    padding: 2px 20px;
    color: #5f5f5f;
}
.det_ven{
    width: 100%;
    padding: 0px 20px;
    margin-bottom: 20px;
    margin-top: -5px;
}
.det_ven button{
    outline: none;
    padding: 5px 20px;
    background-color: /*#083d54*/ #d3bff8;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 15px;
    border-top: 2px solid #6f42c1 ;
    border-right: 2px solid #6f42c1 ;
    border-left: none;
    border-bottom: none;
}
.det_ven button i{
    font-size: 18px !important;
    margin-left: 10px;
}
.img_noresult{
    width: 100%;
    display: flex;
    flex-direction: column;
    color: #5f5f5f;
    align-items: center;
    justify-content: center;
}
.img_noresult img{
    width: 300px;
    height: 300px;
}
@media screen and (max-width: 1120px) {
    .newVen{
        width: 28%;
    }
}
@media screen and (max-width: 795px) {
    .newVen{
        width: 43%;
    }
}
@media screen and (max-width: 551px) {
    .newVen{
        width: 80%;
        margin-left: 0px;
    }
}
@media screen and (max-width: 363px) {
    .newVen{
        width: 90%;
    }
}





.card_details{
    background-color: white !important;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 40px;
}
.detailsTop{
    padding: 20px 40px 20px 40px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(216, 216, 216, 0.592);
}
.detailsTop img{
    width: 50px;
    height: 50px;
    margin-left: 13px;
    border-radius: 50%;
}
.tit_det{
    font-size: 25px;
    font-weight: bold;
    color: #5f5f5f;
}
.span_det{
    color: #5f5f5f;
    font-size: 17px;
}
.more_info_brand{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 20px;
}
.info_brand{
    width: 50%;
    background-color: white;
    box-shadow: 0px 0px 20px -3px rgba(221, 221, 221, 0.808);
    padding: 20px;
    color: #5f5f5f;
    border-radius: 10px;
}
.map_brad{
    width: 40%;
    background-color: rgba(216, 216, 216, 0.716);
    height: 400px;
}
.brand_header_flex{
    display: flex;
    align-items: center;
}
.brand_header_flex h3{
    color: #6f42c1 !important;
}
.brand_header_flex img{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-left: 15px;
    box-shadow: 0px 0px 25px -5px rgb(205, 205, 205);
    background: linear-gradient(rgba(53, 37, 37, 0.2), rgba(0, 0, 0, 0.2)), url(/static/media/logoH.07ff0d2c.png) center/cover no-repeat;
    background-size: contain;
    background-position: center;
}
.rest_info{
    margin: 15px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.rest_info p{
    font-size: 18px;
}
.icon_flex{
    display: flex;
    align-items: center;
}
.icon_flex i{
    font-size: 17px;
    margin-left: 15px;
    color: #6f42c1;
}
.icon_flex img{
    width: 30px;
    height: 30px;
}
.price_product{
    font-size: 17px !important;
}
.color_red{
    color: red !important;
}
.color_yellow{
    color: #FFCE26 !important;
}
.nopProduct{
    width: 100%;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 30px 0px;
}
.nopProduct img{
    width: 80px;
    height: 80px;
}
.nopProduct span{
    color: #5f5f5f;
    margin-top: 5px;
}
@media screen and (max-width: 939px) {
    .more_info_brand{
        flex-direction: column;
    }
    .info_brand{
        width: 92%;
        margin-bottom: 40px;
    }
    .map_brad{
        width: 100%;
    }
}
.mainbox {
    margin: 100px auto;
    height: 600px;
    width: 600px;
    position: relative;
}

.err {
    color: #6f42c1;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 11rem;
    position: absolute;
    left: 20%;
    top: 8%;
}

.far {
    position: absolute;
    font-size: 8.5rem;
    left: 42%;
    top: 15%;
    color: #6f42c1;
}

.err2 {
    color: #6f42c1;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 11rem;
    position: absolute;
    left: 68%;
    top: 8%;
}

.msg {
    text-align: center;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 1.6rem;
    position: absolute;
    left: 16%;
    top: 45%;
    width: 75%;
    color: #5f5f5f;
}
@media screen and (max-width: 645px) {
    .mainbox{
        background-color: red;
        width: 400px;
    }
    .err,.err2{
        font-size: 9rem;
    }
    .far{
        font-size: 6rem;
    }
}
@media screen and (max-width: 420px) {
    .mainbox{
        background-color: red;
        width: 300px;
    }
    .err,.err2{
        font-size: 7.5rem;
    }
    .far{
        font-size: 4.5rem;
    }
}
.LoadingPage{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.LoadingPage img{
    width: 400px;
    height: 400px;
}
